<template>
  <div class="activityPage">
    <img
      src="https://cdn.bubbleplan.cn/static/home/backPage.png"
      alt=""
      class="activityPage-back"
      @click="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/home/rankinglist.png"
      alt=""
      class="activityPage-rankinglist"
      @click="inviteRank()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/home/regulation.png"
      alt=""
      class="activityPage-regulation"
      @click="regulationButton()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/home/record_cc.png"
      alt=""
      class="activityPage-record_cc"
      @click="myInvita()"
    />
    <div class="activityPage-list flex">
      <div class="activityPage-list-li">
        <div class="activityPage-list-button" @click="buyimmediately()">
          立即购买
        </div>
      </div>
      <div class="activityPage-list-li1">
        <div class="activityPage-list-button" @click="show = !show">兑换</div>
      </div>
    </div>
    <div class="activityPage-button flex">
      <div class="activityPage-button-left">
        今日可购买次数：{{ frequency }}
      </div>
      <div class="activityPage-button-right" @click="myInvitation()">
        我的邀请码
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="rankingListShow"
      width="30%"
      :show-close="false"
    >
      <div class="page-content">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="page-content-x"
          @click="rankingListShow = false"
        />
        <div class="page-content-type flex flex-around">
          <div
            v-for="(item, index) in typeList"
            :key="index"
            class="page-content-type-li"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="page-content-ul">
          <div
            v-for="(item, index) in userList.list"
            :key="index"
            :class="
              index == 0
                ? 'page-content-ul-li1'
                : index == 1
                ? 'page-content-ul-li2'
                : index == 2
                ? 'page-content-ul-li3'
                : 'page-content-ul-li'
            "
            style="margin-bottom: 6px"
            class="page-content-ul-box flex items-center flex-between"
          >
            <div style="width: 23%">
              <img
                :src="
                  index == 0
                    ? 'http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9832.png'
                    : index == 1
                    ? 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9833.png'
                    : index == 2
                    ? 'https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9834.png'
                    : ''
                "
                mode=""
                v-if="index < 3"
                :class="
                  index == 0
                    ? 'page-content-ul-box-img'
                    : index == 1
                    ? 'page-content-ul-box-img1'
                    : 'page-content-ul-box-img2'
                "
              />

              <div v-else class="page-content-ul-box-num">
                {{ index + 1 }}
              </div>
            </div>
            <div class="page-content-ul-box-name">{{ item.name }}</div>
            <div class="page-content-ul-box-integral">
              {{ item.count }}
            </div>
          </div>
        </div>
        <div class="page-content-my flex items-center flex-between">
          <div class="sort-sort" style="">
            {{ userList.sort == 0 ? "未上榜" : userList.sort }}
          </div>
          <div class="page-content-my-num" style="margin-left: 50px">
            {{ userList.name }}
          </div>
          <div class="page-content-my-num">{{ userList.count }}</div>
        </div>
      </div>
    </el-dialog>
    <regulation ref="regulationRef"></regulation>
    <myInvitation
      ref="myInvitationRef"
      :subordinateList="subordinateList"
      x
      @mySubordinates="mySubordinates"
    ></myInvitation>

    <el-dialog title="" :visible.sync="show" width="30%" :show-close="false">
      <div class="conversion">
        <div class="conversion-title">正在使用牛仔扣兑换门票</div>
        <div class="conversion-materials flex">
          <div class="conversion-materials-left">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/Frame96372x.png"
              mode=""
              class="conversion-materials-left-img"
            />
            <div class="conversion-materials-left-num">x8</div>
          </div>
          <div class="conversion-materials-left">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/mp2x.png"
              mode=""
              class="conversion-materials-left-img"
            />
            <div class="conversion-materials-left-num">x1</div>
          </div>
        </div>
        <div class="conversion-button" @click="cowboyBuckleToTicket()">
          确认
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import regulation from "./components/regulation.vue";
import myInvitation from "./components/myInvitation.vue";
export default {
  components: {
    regulation,
    myInvitation,
  },
  data() {
    return {
      show: false,
      rankingListShow: false,
      typeList: [
        {
          name: "排名",
        },
        {
          name: "昵称",
        },
        {
          name: "邀请人数",
        },
      ],
      userList: [],
      subordinateList: [],
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      productList: [],
      frequency: 0,
      reminderShowTitle: "",
    };
  },
  mounted() {
    this.activityProductList();
  },
  methods: {
    buyimmediately() {
      this.$router.push({
        path: "/home/commodityDetails/commodityDetails",
        query: {
          ...this.productList[0],
        },
      });
    },
    myInvitation() {
      this.$router.push("/activity/myInvitation");
    },
    // 兑换
    async cowboyBuckleToTicket() {
      const params = {
        count: 1,
      };
      const { data, code, msg } = await this.$ajax.cowboyBuckleToTicket(params);
      this.show = false;
      this.getOwnerNumByType();
      //   if (code == 200) {
      //     this.reminderShowTitle = msg;
      //   } else {
      //     this.reminderShowTitle = msg;
      //   }
      //   this.reminderShow = true;
      //   setTimeout(() => {
      //     this.reminderShow = false;
      //   }, 1500);
    },
    async getOwnerNumByType() {
      const { data, code } = await this.$ajax.getOwnerNumByType({ type: 6 });
      this.ticketsInfo(data);
      // this.ticketsNum = data;
    },
    async activityProductList() {
      //查询首发
      const { data, code } = await this.$ajax.activityProductList({
        ...this.page,
      });
      this.productList = data.list;
      this.getBuyNum();
      return;
    },
    //获取可购买次数
    async getBuyNum() {
      const params = {
        activityId: this.productList[0].id,
      };
      const { data, code } = await this.$ajax.getBuyNum(params);
      this.frequency = data;
    },
    myInvita() {
      this.page.pageNum = 1;
      this.mySubordinates();
      this.$refs.myInvitationRef.myInvitationShow = true;
    },
    //规则
    regulationButton() {
      this.$refs.regulationRef.regulationShow = true;
    },
    //查看下级
    async mySubordinates(type) {
      if (type == "bottom") {
        this.page.pageNum++;
      }
      const params = {
        ...this.page,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      if (type == "bottom") {
        this.subordinateList.push(...data.list);
      } else {
        this.subordinateList = data.list;
      }
    },

    //排行榜
    async inviteRank() {
      const { data, code } = await this.$ajax.inviteRank();
      this.rankingListShow = true;
      this.userList = data;
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.activityPage::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.activityPage {
  width: 100%;
  height: calc(100vh - 94px);
  overflow: auto;
  background: url("https://cdn.bubbleplan.cn/static/home/activityPage.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 101.33px;
  padding-bottom: 200px;
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    width: 72px;
    height: 72px;
    position: absolute;
    top: 66px;
    left: 100px;
  }
  &-rankinglist:hover {
    cursor: pointer;
  }
  &-rankinglist {
    width: 133px;
    height: 112px;
    position: absolute;
    top: 46px;
    left: 301px;
    z-index: 99;
  }
  &-regulation:hover {
    cursor: pointer;
  }
  &-regulation {
    position: absolute;
    top: 240px;
    right: 0;
    width: 221px;
    height: 84px;
  }
  &-record_cc:hover {
    cursor: pointer;
  }
  &-record_cc {
    position: absolute;
    top: 351px;
    right: 0;
    width: 221px;
    height: 84px;
  }
  &-list {
    justify-content: center;
    &-li {
      width: 693px;
      height: 607px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group103962x.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &-li1 {
      width: 738px;
      height: 597px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group103972x.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &-button:hover {
      cursor: pointer;
    }
    &-button {
      width: 258px;
      height: 100px;
      background: url("https://cdn.bubbleplan.cn/static/home/Frame96392x.png")
        no-repeat;
      background-size: 100% 100%;
      margin: auto;
      margin-top: 443.77px;
      color: #343a42;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 32px;
      line-height: 90px;
      font-weight: 800;
    }
  }
  &-button {
    margin-top: 28.3px;
    justify-content: center;
    &-left:hover {
      cursor: pointer;
    }
    &-left {
      width: 355px;
      height: 94px;
      line-height: 84px;
      text-align: center;
      color: #565f6a;
      font-size: 32px;
      font-weight: 800;
      background: url("https://cdn.bubbleplan.cn/static/home/Vector5642x.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
    }
    &-right:hover {
      cursor: pointer;
    }
    &-right {
      width: 310px;
      height: 94px;
      line-height: 84px;
      text-align: center;
      color: #fffaee;
      font-size: 32px;
      font-weight: 800;
      background: url("https://cdn.bubbleplan.cn/static/home/Group98572x.png")
        no-repeat;
      background-size: 100% 100%;
      margin-left: 30px;
    }
  }
  :deep(.el-dialog__wrapper)::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  :deep(.el-dialog__header) {
    padding: 0 !important;
  }
  :deep(.el-dialog__body) {
    padding: 0 !important;
  }
  :deep(.el-dialog) {
    //   width: 676px !important;
    background: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .page-content {
    width: 676px;
    height: 921px;
    background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9857.png")
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &-x {
      cursor: pointer;
    }
    &-x {
      position: absolute;
      right: -25.12px;
      top: 82px;
      width: 69.12px;
      height: 68px;
    }
    &-type {
      padding: 0 42px;
      padding-top: 204px;
      color: #87697f;
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 19px;

      &-li:nth-child(1) {
        color: #7036ec;
        width: 20%;
      }

      &-li {
        width: 35%;
        text-align: center;
      }
    }
    &-ul::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-ul {
      flex: 1;
      padding: 0 25px;
      overflow: auto;

      &-li1 {
        background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9837.png")
          no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 95px;
      }

      &-li2 {
        background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9858.png")
          no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 95px;
      }

      &-li3 {
        background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9859.png")
          no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 95px;
      }

      &-li {
        background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/m_bubble/turntableRaffle/Group9860.png")
          no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 95px;
      }

      &-box {
        padding: 0 41px;

        &-img {
          width: 95px;
          height: 110px;
          margin-left: -12px;
          margin-top: -22px;
        }

        &-img1 {
          width: 80px;
          height: 80px;
        }

        &-img2 {
          width: 80px;
          height: 80px;
        }

        &-num {
          // width: 20%;
          height: 81px;
          text-align: center;
          color: #fcf5ee;
          font-size: 60px;
          font-family: "qiantuhouheiti";
        }

        &-name {
          color: #2f2f2f;
          text-align: center;
          font-size: 24px;
          font-family: "Source Han Sans SC";
          font-weight: 900;
          width: 37%;
        }

        &-integral {
          width: 35%;
          color: #2f2f2f;
          text-align: center;
          font-family: "Source Han Sans SC";
          font-size: 24px;
          font-weight: 900;
        }
      }
    }
    .sort-sort {
      font-size: 34px;
      color: #fcf5ee;
      font-weight: bold;
      text-align: center;
    }
    &-my {
      width: 100%;
      background: url("https://cdn.bubbleplan.cn/m_bubble/turntableRaffle/Group9838.png")
        no-repeat;
      background-size: 100% 100%;
      height: 92.93px;
      width: 621.83px;
      margin: auto;
      margin-bottom: 20px;
      padding: 0 21px;
      padding-bottom: 16px;

      &-num {
        color: #ffffff;
        width: 30%;
        text-align: center;
        font-size: 24px;
        font-weight: 900;
        font-family: "Source Han Sans SC";
      }
    }
  }
  .conversion {
    width: 705px;
    height: 412px;
    background: url("https://cdn.bubbleplan.cn/m_bubble/activity/Group103992x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 68px 71px;

    &-title {
      color: #e6c9e7;
      font-size: 23px;
      font-weight: 900;
      text-align: center;
    }

    &-materials {
      margin-top: 36px;
      justify-content: space-between;

      &-left {
        position: relative;

        &-img {
          width: 174px;
          height: 144px;
        }

        &-num {
          position: absolute;
          color: #ffffff;
          font-size: 32px;
          font-family: "TsangerYuMo";
          font-weight: 800;
          top: 117px;
          left: 70px;
          text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000,
            3px 3px 0 #000;
          /* 模拟描边效果 */
        }
      }
    }
    &-button:hover {
      cursor: pointer;
    }
    &-button {
      margin: auto;
      margin-top: 54px;
      width: 202px;
      height: 79px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/activity/Vector5942x.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      text-align: center;
      font-size: 30px;
      font-family: "TsangerYuMo";
      font-weight: 800;
      text-align: center;
      line-height: 79px;
      text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000,
        3px 3px 0 #000;
      /* 模拟描边效果 */
    }
  }
}
</style>