<template>
  <el-dialog
    title=""
    :visible.sync="regulationShow"
    width="60%"
    :show-close="false"
  >
    <div class="regulationShows">
      <div class="regulationShows-title">
        <img
          src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
          alt=""
          class="regulationShows-x"
          @click="regulationShow = false"
        />规则
      </div>
      <div class="regulationShows-text">
        <div>活动时间内每个 ID 每天可 mint 牛仔扣一次。</div>
        <div class="regulationShows-text-top">
          每成功邀请一个新用户实名并开通钱包，邀请者即可获得一次额外mint资格。邀请次数无限制，额外mint资格无上限并将在使用后刷新。
        </div>
        <div class="regulationShows-text-top">
          （已绑定邀请码用户：成功开通钱包后邀请者即可获得一次额外mint资格）
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      regulationShow: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.regulationShows {
  width: 1186px;
  height: 745px;
  background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 78px 124px 103.34px 168px;
  &-x {
    cursor: pointer;
  }
  &-x {
    position: absolute;
    top: 63.96px;
    right: 36.76px;
    width: 80.88px;
    height: 79.56px;
  }
  &-title {
    width: 100%;
    color: #574352;
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }
  &-text::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-text {
    flex: 1;
    overflow: auto;
    margin-top: 40px;
    color: #574352;
    font-size: 24px;
    line-height: 40.8px;
    // background: #d6c4ba;
    padding: 45px 50px;
    &-top {
      margin-top: 30px;
    }
  }
}
</style>